import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import styled from "styled-components"
import Layout from "../components/SubLayout"
import { navItemsEn } from "../constants/navigation"

import React from "react"

import {
  Card,
  CardsWrapper,
  H1,
  Height,
  Icon,
  P,
  TextWrapper,
  WrapperHero,
} from "../styled/about"

const StyledImage = styled(GatsbyImage)`
  max-width: 420px;
  max-height: 420px;
  width: 100%;
  height: 100%;
`
class About extends React.Component {
  render() {
    const image = getImage(
      this.props.data.about.childImageSharp.gatsbyImageData
    )
    return (
      <Layout
        data={this.props.data}
        location={this.props.location}
        navItems={navItemsEn}
      >
        <Helmet
          title="About us - Miia"
          meta={[
            { name: "description", content: "Sample" },
            { name: "keywords", content: "sample, something" },
          ]}
        />
        <Height>
          <WrapperHero>
            <TextWrapper>
              <H1>About us</H1>
              <P>
                Due to great family love we have towards pets, in the year 2000
                we decided to found a company which produces and sells extremely
                modern and high-quality mattresses and pet beds. Taking into
                account the quality conditions of our products, through our
                family’s automated manufactory, we maintain a good organization
                and respect delivery deadlines both on the internal and global
                market. We have extremely modern technology and equipment in
                order to fully meet the expectations of our clients. Our team
                consists of very dedicated and trained modellers, designers,
                tailors and seamstresses.
              </P>
            </TextWrapper>
            <StyledImage image={image} alt="" />
          </WrapperHero>
          <CardsWrapper>
            <Card>
              <Icon fixed={this.props.data.dog.sharp.fixed} />
              <h4>Care for little family members</h4>
              <p>
                Every pet has a unique character and needs. Because of this
                reason, when creating our products we choose only the highest
                quality materials and precise shapes that are anatomically
                adapted to the natural needs of pets.
              </p>
            </Card>
            <Card>
              <Icon fixed={this.props.data.maintenance.sharp.fixed} />
              <h4>Design and maintenance</h4>
              <p>
                Design is retro textured material. The products are made of
                natural high-quality fabrics, the base is non-slip, while the
                inside of the product is filled with high-quality memory foam or
                thermal wool. Fabrics are waterproof materials that are very
                pleasant to the touch. Maintenance and cleaning is very easy, at
                30 ºC.
              </p>
            </Card>
            <Card>
              <Icon fixed={this.props.data.productIcon.sharp.fixed} />
              <h4>Wide range of products</h4>
              <p>
                Miia brend offers a wide range of products – pet beds, pet beds
                with a heater, sofas and pet crates for vehicles. The products
                are made in M, L, XL and XXL sizes.
              </p>
            </Card>
          </CardsWrapper>
        </Height>
        <div style={{ height: "48px" }}></div>
      </Layout>
    )
  }
}

export default About

export const aboutQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    dog: file(relativePath: { eq: "dog.png" }) {
      sharp: childImageSharp {
        fixed(width: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    maintenance: file(relativePath: { eq: "maintenance.png" }) {
      sharp: childImageSharp {
        fixed(width: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    about: file(relativePath: { eq: "about-transparent.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
    mainlogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
    productIcon: file(relativePath: { eq: "productIcon.png" }) {
      sharp: childImageSharp {
        fixed(width: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    remark: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/absr/" } }
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            height
            width
            blob2d {
              publicURL
            }
            image {
              sharp: childImageSharp {
                fluid(maxWidth: 560) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
